import React, { useState } from 'react'
import Footer from '../../components/footer'
import { graphql } from 'gatsby'
import '../../styles/solution/se-asia.less'
import SEO from '../../components/SEO'
import { SolutionTopBanner, ProductBlog } from '../../components/block'
const loadText = require('src/utils').loadText
const static_data = loadText('southeast-asia')
import ResourceMap, { CityGroup } from '../../components/map'
import { Solution, Feedback } from '../products/bare-metal-cloud'
import CompleteSolutions from '../../components/block/solution/CompleteSolution'
import Challenges from '../../components/block/solution/Overview'
import Market from '../../components/block/solution/Differences'
import Drop from '../../components/drop'
// const Market = () => {
//   const data = static_data.market
//   return (
//     <div className="se-asia-market">
//       <div className="title">{data.title}</div>
//       <div className="se-asia-market-itemBg">
//         {data.content.map(({ title, content }, i) => (
//           <div className="se-asia-market-item">
//             <div className="left">
//               <img
//                 src={`/solution/se-asia/market-${i + 1}.png`}
//                 alt={`market-${i + 1}`}
//               />
//             </div>
//             <div className="right">
//               <div className="title">{title}</div>
//               {content.map(c => (
//                 <div className="content">{c}</div>
//               ))}
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   )
// }
export const Map = ({ cities, title, description, initPos }) => {
  const [highlight, setHighlight] = useState([])
  return (
    <div className="se-asia-map">
      <h2 className="font-36 semi-bold t-color mobile-padding">{title}</h2>
      <div
        className="font-20 mt-24 block-middle mobile-padding"
        style={{ maxWidth: '981px' }}
      >
        {description}
      </div>
      <div>
        <CityGroup
          data={cities}
          onClick={(coord) => {
            setHighlight(coord)
          }}
        />
        <ResourceMap highlight={highlight} initPos={initPos} />
      </div>
    </div>
  )
}
// const Challenges = () => {
//   const data = static_data.challenges
//   return (
//     <div className="se-asia-challenges">
//       <div className="title">{data.title}</div>
//       <div className="description">{data.description}</div>
//       {data.content.map(({ title, content }, i) => (
//         <div className="se-asia-challenges-item">
//           <div className="left">
//             <img
//               src={`/solution/se-asia/challenges-${i + 1}.png`}
//               alt={`challenges-${i + 1}`}
//             />
//           </div>
//           <div className="right">
//             <div className="title">{title}</div>
//             <div className="content">{content}</div>
//           </div>
//         </div>
//       ))}
//     </div>
//   )
// }
const Difference = () => {
  const data = static_data.difference
  return (
    <div className="se-asia-difference">
      <div className="se-asia-difference-bg">
        <div className="se-asia-difference-content">
          <div>
            <img src="/solution/se-asia/difference.png" alt="difference" />
          </div>
          <div className="right">
            <h2 className="title white">{data.title}</h2>
            {data.description.map((d) => (
              <div className="description">{d}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
const B1 = () => {
  const data = static_data.b1
  return (
    <div className="se-asia-b1">
      <div className="se-asia-b1-bg">
        <div className="se-asia-b1-content">
          <div className="left">
            <h2 className="title white">{data.title}</h2>
            <div className="description">{data.description}</div>
          </div>
          <div className="right">
            <img src="/solution/se-asia/b1.png" alt="b1" />
          </div>
        </div>
      </div>
    </div>
  )
}
const B2 = () => {
  const data = static_data.b2
  return (
    <div className="se-asia-b2">
      <div className="se-asia-b2-bg">
        <div className="se-asia-b2-content">
          <div className="left">
            <h2 className="title">{data.title}</h2>
            <div className="description">{data.description}</div>
          </div>
          <div className="right">
            <div className="right">
              <img src="/solution/se-asia/b2.png" alt="b2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const B3 = () => {
  const data = static_data.b3
  return (
    <div className="se-asia-b3">
      <div className="se-asia-b3-bg">
        <div className="se-asia-b3-content">
          <div className="left">
            <h2 className="title">{data.title}</h2>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
const B4 = () => {
  const data = static_data.b4
  return (
    <div className="se-asia-b4">
      <div className="se-asia-b4-bg">
        <div className="se-asia-b4-content">
          <div className="left">
            <h2 className="title">{data.title}</h2>
            <div className="description">{data.description}</div>
          </div>
          <div className="right">
            <img src="/solution/se-asia/b4.png" alt="b4" />
          </div>
        </div>
      </div>
    </div>
  )
}
const ISP = () => {
  const data = static_data.isp
  return (
    <div className="se-asia-isp">
      <h2 className="title">{data.title}</h2>
      <div className="description">{data.description}</div>
      {data.content.map(({ country, isp }) => (
        <div className="se-asia-isp-block">
          <h3 className="title">{country}</h3>
          <div className="se-asia-isp-row">
            {isp.map((i) => (
              <div className="se-asia-isp-item">
                <img src={`/solution/se-asia/isp/${i}.png`} alt={i} />
                <div className="se-asia-isp-item-name">{i}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default function Home({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="se-asia">
      <SEO {...static_data.seo} featuredImage="/featuredImage/se-asia.jpg" />
      <SolutionTopBanner
        {...static_data.topBanner}
        backgroundAnimated={true}
        bgColor="linear-gradient(180deg,#F5F6FF 0%,#E7EAFF 100%)"
        bgPic={'/solution/se-asia/topBanner-bg.svg'}
        source="southeast-asia"
        pic={{
          src: '/solution/se-asia/topBanner-pic.png',
          style: {
            right: 0,
            bottom: '50px',
            // top: 0,
            position: 'absolute',
          },
        }}
        picM={{
          src: '/solution/se-asia/topBanner-pic.png',
          style: {
            // right: 0,
            bottom: '88px',
            // top: 0,
            left: '48px',
            position: 'absolute',
          },
        }}
        bannerPic={{
          src: '/solution/se-asia/topBanner-bg-m.svg',
          style: {
            backgroundPosition: 'top center',
          },
        }}
      />
      <Market
        title={static_data.market.title}
        description={static_data.market.description}
        bgPic="/solution/se-asia/market-bg.svg"
        content={static_data.market.content.map((c, i) => ({
          ...c,
          img: `/solution/se-asia/market-${i + 1}.png`,
          imgM: `/solution/se-asia/market-${i + 1}-m.png`,
        }))}
      />
      <Challenges
        title={static_data.challenges.title}
        description={static_data.challenges.description}
        bgPic="/solution/se-asia/challenges-bg.svg"
        data={static_data.challenges.content.map((c, i) => ({
          ...c,
          img: `/solution/se-asia/challenges-${i + 1}.png`,
          icon: `/solution/se-asia/challenges-icon-${i + 1}.svg`,
        }))}
      />
      <div style={{ background: '#f5f9ff' }}>
        <Difference />
      </div>
      <ISP />
      <B1 />
      <B2 />
      <B3 />
      <B4 />

      <Map
        initPos={{ zoom: 4, latlng: [21, 76] }}
        cities={static_data.cities}
        title={static_data.map.title}
        description={static_data.map.description}
      />
      <CompleteSolutions background="linear-gradient(#967DFF, #5B70FF)" />
      <div>
        <Drop color="rgb(92,111,255)" />
        <Solution {...static_data.solution} />
      </div>
      <ProductBlog
        title={static_data.blog.title}
        // bgColor="#fff"
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        tags: {
          elemMatch: {
            name: {
              in: [
                "Singapore"
                "Indonesia"
                "Malaysia"
                "Thailand"
                "Philippines"
                "Vietnam"
                "Myanmar"
              ]
            }
          }
        }
        author: {}
        published: { eq: true }
      }
    ) {
      edges {
        node {
          slug
          wp_date
          content
          categories {
            name
          }
          title
          created_at
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
  }
`
